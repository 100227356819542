@font-face {
    font-family: 'Bellaboo';
    src: url('/themes/wf/assets/fonts/Bellaboo-Regular.eot');
    src: url('/themes/wf/assets/fonts/Bellaboo-Regular.eot?#iefix') format('embedded-opentype'),
        url('/themes/wf/assets/fonts/Bellaboo-Regular.woff2') format('woff2'),
        url('/themes/wf/assets/fonts/Bellaboo-Regular.woff') format('woff'),
        url('/themes/wf/assets/fonts/Bellaboo-Regular.svg#Bellaboo-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: auto;
}