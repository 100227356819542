.reveal-overlay {
  padding: 1rem;
}

.info-modal {
  width: 100%;
  max-width: 40.6rem;
  padding: 2rem 1rem;
  outline: none;
  height: auto;
  min-height: 0px;

  @include breakpoint (medium) {
    padding: 2.5rem;
  }

  .heading {
    display: block;
    color: $black;
    font-size: 1.7em;
    margin-bottom: 2rem;
  }

  .close-button {
    position: absolute;
    right: 0.85rem;
    top: 0.85rem;
    width: 1rem;
    height: 1rem;
    
    @include breakpoint (medium) {
      top: 1.8rem;
      right: 1.8rem;
    }
  }
  .close-button:before, .close-button:after {
    position: absolute;
    left: 0.3rem;
    content: ' ';
    height: 1.125rem;
    width: 0.25rem;
    border-radius: 0.3rem;
    background-color: $black;
  }
  .close-button:before {
    transform: rotate(45deg);
  }
  .close-button:after {
    transform: rotate(-45deg);
  }
}

.modal-link {
  outline: none;
}