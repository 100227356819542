// Variables;
@import 'settings';

.accordion-menu {
    &.card  {
        padding: 0;
    }

    li {
        border-top: 1px solid $lighter-gray;

        &:first-child {
            border-top: none;
        }
    }

    a {
        padding: 1.25rem 3rem 1.25rem 1.25rem;
        line-height: 140%;
        color: $black;
        font-weight: $global-weight-semibold;
    }

    // overrides

    .nested.is-accordion-submenu {
        margin-left: 0;

        a {
            padding: 1.25rem;
            background-color: $xlight-blue;
            font-weight: $global-weight-normal;
            font-size: 0.91rem;
        }
    }

    .is-accordion-submenu-parent:not(.has-submenu-toggle)>a::after {
        content: url('../../assets/svg/icn-plus.svg');
        border: none;
        top: 40%;
        right: 2rem;
    }

    .is-accordion-submenu-parent[aria-expanded=true]>a::after {
        content: url('../../assets/svg/icn-minus.svg');
        -webkit-transform: none;
        transform: none;
        -webkit-transform-origin: 0;
        transform-origin: 0;
    }
}