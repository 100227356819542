.switch {
    left: rem-calc(-33);

    @include breakpoint(medium) {
        left: rem-calc(-26);
    }

    .switch-paddle {
        border-radius: 50px;
        background-color: $light-blue;

        &:after {
            border-radius: 50px; 
        }
    }

    input:checked~.switch-paddle {
        background: $light-blue;
    }

    input[type=checkbox] {
        cursor: auto;
    }

    .switch-on.h5,
    .switch-off.h5 {
        position: relative;
        display: block;
        font-size: 1em;
        top: 50%;
        transform: translateY(-50%);

        @include breakpoint(medium) {
            font-size: 1rem;
        }

        &.left {
            left: -6.75rem;

            @include breakpoint(medium) {
                left: rem-calc(-130);
            }
        }

        &.right {
            right: -4.6rem;

            @include breakpoint(medium) {
                right: rem-calc(-92);
            }
        }
    }

    .switch-on.h5 {
        color: $light-blue;
    }

    .switch-off.h5 {
        color: $dark-gray;
    }

}