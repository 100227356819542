ul.tabs {
    border: none;
    background: transparent;

    .tabs-title {
        background: #FEFEFE;
        box-shadow: inset 0 -10px 20px -10px rgba(45,44,49,0.10);
        width: 32%;
        margin-right: 1%;

        &:hover {
            background: $white;
        }

        a {
            text-align: center;
            padding: rem-calc(12);
            opacity: 0.7;

            @include breakpoint(medium) {
                padding: rem-calc(24);
            }
        }

        a:focus, a[aria-selected=true] {
            background: $white;
            border-radius: rem-calc(4) rem-calc(4) 0 0;
            opacity: 1;
        }

        &:focus, h5, a {
            outline: none;
        }

        a:hover {
            box-shadow: inset 0 -10px 20px -10px rgba(45,44,49,0.10);
            opacity: 1;
        }

        a[aria-selected=true]:hover {
            box-shadow: none;
        }
    }
}

.tabs-content {
    border: none;
    border-radius: 0 rem-calc(4) rem-calc(4);

    &.card {
        padding: rem-calc(20);

        @include breakpoint(medium) {
            padding: rem-calc(30);
        }
    }
}

.covered-components-tab {
    .tabs-title {
        img {
            display: block;
            text-align: center;
            margin: 0 auto rem-calc(6);
            height: rem-calc(12);

            @include breakpoint(medium) {
                display: inline-block;
                text-align: left;
                margin: 0 rem-calc(8) rem-calc(6) 0;
            }
        }

        .h5 {
            font-size: rem-calc(14);

            @include breakpoint(medium) {
                font-size: rem-calc(16);
            }
        }
    }

    .tabs-panel {
        img {
            margin: 0 rem-calc(4) rem-calc(5) 0;
        }
        p.small {
            margin: rem-calc(6) 0 rem-calc(30) rem-calc(26);
        }
    }
}