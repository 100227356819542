.button {
    font-size: 1.125rem;
    line-height: 130%;
    font-family: $body-font-family;
    font-weight: $global-weight-bold;
    border-radius: $global-radius;
    color: $white;
    display: inline-block;
    position: relative;
    padding: 0.5rem 1.25rem 0.5rem;
    transition: background-color 250ms;
    transform: translateZ(0);

    &:disabled, &.disabled {
        color: $white;
    }

    &.small {
        font-size: 1rem;
        line-height: 100%;
        padding: 0.625rem 0.5rem;
    }

    &.primary {
        background-color: $primary-color;

        &:hover:not([disabled]):not(.disabled) {
            background-color: darken($primary-color, 8%);
        }
    }

    &.full {
        width: 100%;
    }

    &.small-full {
        width: 100%;

        @include breakpoint(medium) {
            width: auto;
        }
    }

    &.secondary {
        background-color: $royal-blue;

        &:hover:not([disabled]):not(.disabled) {
            background-color: darken($royal-blue, 10%);
        }
    }

    &:hover:not([disabled]):not(.disabled),
    &:focus {
        color: $white;
    }

    &.tertiary {
        background-color: $warning-color;
        color: $black;

        &:hover:not([disabled]):not(.disabled) {
            background-color: darken($warning-color, 5%);
            color: $black;
        }

        &:disabled, &.disabled {
            color: $black;
        }
    }
}

a.button.secondary {
    color: $white;
}

button, input.button {
    &:focus {
        outline: none;
    }
}