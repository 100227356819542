// html, body {
//     overflow-x: hidden;
// }

body > header,
body > .position-sticky > header {
    padding-top: 5rem;

    @include breakpoint(medium) {
        padding-top: $header-padding-top;
    }
}

.medium-grid-container {
    @include breakpoint(medium) {
        @include xy-grid-container;
    }
}

.medium-align-left {
    @include breakpoint(medium) {
        @include flex-align(left);
    }
}

.diamond-divider img {
    margin-left: auto;
    margin-right: auto;
}

.border-top {
    border-top: 1px solid $light-gray;
}

.border-right {
    border-right: 1px solid $light-gray;
}

.border-bottom {
    border-bottom: 1px solid $light-gray;
}

.border-left {
    border-left: 1px solid $light-gray;
}

ul {
    li {
        list-style: none;
    }
}

hr {
    border-bottom: 1px solid #EEE;
}

.product-logo {
    width: 100px;
}