ul {
    margin-left: 0;
}

.ts-and-cs {
    ul,
    ol {
        li {
            margin: 1.25rem 0;
            line-height: 150%;
        }
    }
}