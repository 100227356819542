// Variables;
@import 'settings';

.breadcrumb {
    ul {
        display: flex;
        justify-content: center;
        margin-left: 0;

        li {
            font-family: $header-font-family;
            font-weight: $global-weight-bold;
            line-height: 120%;
            margin: 0.5rem rem-calc(25) 0 0;
            position: relative;
            color: $dark-gray;

            @include breakpoint(medium) {
                margin-right: rem-calc(56);
            }

            &.active {
                color: $black;
            }

            &:after {
                content: "";
                display: inline-block;
                width: rem-calc(10);
                height: 1px;
                position: absolute;
                background: $dark-gray;
                top: 38%;
                left: 115%;

                @include breakpoint(medium) {
                    width: rem-calc(30);
                    left: 125%;
                }
            }

            &:last-child {
                margin-right: 0;

                &:after {
                    content: none;
                }
            }
        }
    }
}