@font-face {
    font-family: 'Khula';
    src: url('/themes/wf/assets/fonts/Khula-Light.eot');
    src: url('/themes/wf/assets/fonts/Khula-Light.eot?#iefix') format('embedded-opentype'),
        url('/themes/wf/assets/fonts/Khula-Light.woff2') format('woff2'),
        url('/themes/wf/assets/fonts/Khula-Light.woff') format('woff'),
        url('/themes/wf/assets/fonts/Khula-Light.svg#Khula-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Khula';
    src: url('/themes/wf/assets/fonts/Khula-Semibold.eot');
    src: url('/themes/wf/assets/fonts/Khula-Semibold.eot?#iefix') format('embedded-opentype'),
        url('/themes/wf/assets/fonts/Khula-Semibold.woff2') format('woff2'),
        url('/themes/wf/assets/fonts/Khula-Semibold.woff') format('woff'),
        url('/themes/wf/assets/fonts/Khula-Semibold.svg#Khula-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Khula';
    src: url('/themes/wf/assets/fonts/Khula-Bold.eot');
    src: url('/themes/wf/assets/fonts/Khula-Bold.eot?#iefix') format('embedded-opentype'),
        url('/themes/wf/assets/fonts/Khula-Bold.woff2') format('woff2'),
        url('/themes/wf/assets/fonts/Khula-Bold.woff') format('woff'),
        url('/themes/wf/assets/fonts/Khula-Bold.svg#Khula-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Khula';
    src: url('/themes/wf/assets/fonts/Khula-Regular.eot');
    src: url('/themes/wf/assets/fonts/Khula-Regular.eot?#iefix') format('embedded-opentype'),
        url('/themes/wf/assets/fonts/Khula-Regular.woff2') format('woff2'),
        url('/themes/wf/assets/fonts/Khula-Regular.woff') format('woff'),
        url('/themes/wf/assets/fonts/Khula-Regular.svg#Khula-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Khula';
    src: url('/themes/wf/assets/fonts/Khula-ExtraBold.eot');
    src: url('/themes/wf/assets/fonts/Khula-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('/themes/wf/assets/fonts/Khula-ExtraBold.woff2') format('woff2'),
        url('/themes/wf/assets/fonts/Khula-ExtraBold.woff') format('woff'),
        url('/themes/wf/assets/fonts/Khula-ExtraBold.svg#Khula-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: auto;
}
