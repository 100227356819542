.bg-white.contact-block {
    p {
        margin-bottom: 0;
    }
}

.here-to-help {
    h2 {
        font-size: 1.2rem;

        @include breakpoint(medium) {
            font-size: rem-calc(24);
        }
        @include breakpoint(large) {
            font-size: rem-calc(36);
        }
    }
    .employee-picture .headshot {
        bottom: 0;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        height: 90%;

        @include breakpoint(medium) {
            height: 130%;
        }
    }

    .home {
        height: rem-calc(160);

        @media screen and (min-width: rem-calc(430)) and (max-width: rem-calc(640)) {
            height: rem-calc(250);
        }

        @include breakpoint(medium) {
            height: rem-calc(260);
        }

        @media screen and (min-width: rem-calc(800)) and (max-width: rem-calc(1060)) {
            height: rem-calc(360);
        }
        
        @media screen and (min-width: rem-calc(1061)) and (max-width: rem-calc(1200)) {
            height: rem-calc(420);
        }

        @media screen and (min-width: rem-calc(1201)) {
            height: rem-calc(440);
        }

        img.position-absolute {
            bottom: 0;
            left: 0;
        }
    }
}