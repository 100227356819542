.menu-overlay {
    background: $black;
    opacity: 0;
    transition: visibility 0s .2s, opacity .2s;
    visibility: hidden;
    z-index: 100;

    // Allow vertical scrolling on short devices
    .grid-y {
        @media screen and (max-height: 540px) {
            justify-content: start;
            overflow-y: auto;
        }

        @media screen and (min-width: ($breakpoints, medium)) and (max-height: 700px) {
            justify-content: start;
            overflow-y: auto;
        }
    }

    a {
        color: $white;

        &:hover {
            color: $warning-color;
        }
    }

    &.is-active {
        opacity: 1;
        transition: opacity .2s, visibility 0s;
        visibility: visible;
    }
}

.large-menu {
    ul {
        margin-bottom: 0;

        li {
            @include breakpoint(medium) {
                margin-bottom: 1rem;
            }
        }
    }

    a {
        @extend .h2;
        line-height: 1.7;
        background-color: $white;
        color: $black;

        &:hover {
            color: $warning-color;
            background-color: $black;
        }

        @include breakpoint(medium) {
            line-height: $header-lineheight;
        }
    }
}

.small-menu {

    ul {
        margin-top: 1rem;
        line-height: 2.5;

        @include breakpoint(medium) {
            line-height: 1;
        }
    }

    a {
        @extend .h4;
        line-height: 1;
    }
}