.reveal-overlay {
  padding: 1rem;
}
.whats-covered-modal {
  width: 100%;
  max-width: 53.12rem;
  padding: 1.87rem;
  outline: none;
  transform: translateY(-0.5rem);

  .whats-covered-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.62rem;

    .product-logo {
      margin-bottom: 0.62rem;
    }

    .whats-covered-title {
      margin: 0 0 0 0.5rem;
    }
  }

  .whats-covered-para {
    margin-top: 1.25rem;
  }

  .center-content {
    width: 100%;
    max-width: 36.56rem;
    margin: 0 auto;
  }

  .whats-covered-content {
    .covered-components-list {
      ul {
        margin-left: 0px;
      }
    }
  }

  hr {
    border-bottom: 1px solid rgba(45,44,49,0.2);
    margin: 2.5rem 0;
  }

  .close-button {
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 0.93rem;
    height: 0.93rem;

    @include breakpoint(medium) {
      right: 1.87rem;
      top: 1.87rem;
    }
  }
  .close-button:before, .close-button:after {
    position: absolute;
    left: 0.31rem;
    content: ' ';
    height: 1.12rem;
    width: 0.25rem;
    border-radius: 0.31rem;
    background-color: #2D2C31;
  }
  .close-button:before {
    transform: rotate(45deg);
  }
  .close-button:after {
    transform: rotate(-45deg);
  }

  @include breakpoint(small) {
    .whats-covered-content {
      text-align: left;
    }
    .whats-covered-checklist {
      margin-top: 0!important;
      margin-bottom: 0!important;
    }
  }
  @include breakpoint(medium) {
    .whats-covered-content {
      text-align: center;
    }
    .whats-covered-checklist {
      margin-top: 4rem!important;
      margin-bottom: 2rem!important;
    }
  }
}

.modal-link {
  outline: none;
}