// Variables;
@import 'settings';

.bg-l-blue.headshot-hero {
    .grid-x.position-relative {
        min-height: rem-calc(500);

        @include breakpoint(medium) {
            min-height: rem-calc(420);
        }

        @include breakpoint(xmedium) {
            min-height: rem-calc(500);
        }

        @include breakpoint(large) {
            min-height: rem-calc(360);
        }
    }

    img.position-absolute.left,
    img.position-absolute.right {
        bottom: 0;
        height: 38%;

        @include breakpoint(medium) {
            height: 55%;
        }

        @include breakpoint(xmedium) {
            height: 65%;
        }

        @media screen and (min-width: rem-calc(1024)) and (max-width: rem-calc(1128)) {
            height: 70%;
        }

        @media screen and (min-width: rem-calc(1129)) {
            height: 85%;
        }
    }

    img.position-absolute.left {
        left: 0;
    }

    img.position-absolute.right {
        right: 0;
    }

    @media screen and (min-width: rem-calc(801)) and (max-width: rem-calc(1145)) {
        h1 {
            font-size: rem-calc(40);
        }
    }
}