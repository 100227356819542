table {
    tbody, th, td {
        border: 1px solid $lighter-gray;
    }

    &.no-border {
        tbody, th, td {
            border: none;
        }
    }

    th, td {
        color: $black;
        font-size: 0.9375rem;
        padding: rem-calc(12);
    }

    .smaller {
        th, td {
            padding: rem-calc(10) rem-calc(12);
        }
    }

    tr:nth-child(even) {
        background: $white;
    }

    tr:nth-child(odd) {
        background: $xlight-gray;
    }

    th {
        font-weight: 600;
    }

    .heading {
        font-weight: $global-weight-semibold;
    }
}

.included-components table {
    th:nth-child(2),
    th:nth-child(3),
    th:nth-child(4),
    td:nth-child(2),
    td:nth-child(3),
    td:nth-child(4) {
        width: 60px;
        text-align: center;
    }

    th, td {
        border: none;
    }

    tr:nth-child(even) {
        background: $xlight-gray;
    }

    tr:nth-child(odd) {
        background: $white;
    }

    th:first-child,
    th:nth-child(2),
    th:nth-child(3),
    td:first-child,
    td:nth-child(2),
    td:nth-child(3) {
        border-right: 1px solid $lighter-gray;
    }
}

.included-components table,
.special-benefits table {
    th img {
        height: rem-calc(12);
    }
}

.special-benefits table {
    th:nth-child(2) {
        border-left: 1px solid $lighter-gray;
        border-right: 1px solid $lighter-gray;
    }
    
    tr {
        &:nth-child(even) {
            background: $white;
        }

        &:nth-child(odd) {

            td:nth-child(2) {
                border-left: 1px solid $lighter-gray;
                border-right: 1px solid $lighter-gray;
            }
        }
    }
    
    td {
        &.heading {
            font-weight: 600;
            padding-top: 1.5rem;
        }

        &:not(.heading) {
            text-align: center;
        }
    }
}