.position-sticky {
    position: sticky !important;

    &.bottom {
        bottom: 0;
    }

    &.top {
        top: 0;
    }

}

@for $i from 1 through 10 {
    .z-#{$i} {
        z-index: $i;
    }
}
