// Fonts that are used on every page should be imported here
@import 'fonts/khula.scss';
@import 'fonts/open-sans.scss';
@import 'fonts/bellaboo.scss';

h1, h2, h4,
.h1, .h2, .h4,
.chunky-heading {
    font-family: $header-font-family;
    font-weight: $global-weight-bold;
}

h3, h4, h5, h6,
.h3, .h4, .h5, .h6,
.mini-heading {
    font-family: $body-font-family;
}

h1, .h1 {
    margin-bottom: 0;
    line-height: 1.2;
}

h4, h5, h6,
.h4, .h5, .h6 {
    font-weight: $global-weight-semibold;
}

h5, .h5 {
    text-transform: uppercase;
}

.chunky-heading {
    font-size: 1.4rem;
}

.mini-heading {
    font-size: rem-calc(14);
    font-weight: $global-weight-semibold;
}

.label {
    background: none;
    color: $black;
    font-weight: $global-weight-semibold;
    font-size: 0.875rem;
    padding: 0;
}

.bold {
    font-weight: $global-weight-bold;
}

// Paragraph and li text

.small-centered {
    text-align: center;

    @include breakpoint(medium) {
        text-align: left;
    }
}

.small-centered-from-right {
    text-align: center;

    @include breakpoint(medium) {
        text-align: right;
    }
}

.small-left-aligned {
    text-align: left;

    @include breakpoint(medium) {
        text-align: center;
    }
}

.regular-case {
    text-transform: none !important;
}

.uppercase {
    text-transform: uppercase !important;
}

.microcopy {
    font-size: 0.75rem;
}

p {
    line-height: 150%;

    &.small {
        font-size: 0.875rem
    }

    &.smaller {
        font-size: 0.825rem
    }

    &.script {
        font-family: 'Bellaboo';
        font-size: 2.25rem;
    }
}

.small {
    font-size: 0.875rem;
}

.text-white {
    color: $white !important;

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
        color: $white !important;
    }
}

.text-black {
    color: $black;

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
        color: $black !important;
    }
}

.text-primary {
    color: $primary-color;
}

.text-warning {
    color: $warning-color;
}

.text-alert {
    color: $alert-color;
}

.font-body {
    font-family: $body-font-family;
    font-size: 1rem;
}

.lead {
    font-size: rem-calc(16);
    color: $body-font-color;

    @include breakpoint(medium) {
        font-size: rem-calc(18);
    }

    &.large {
        @include breakpoint(medium) {
            font-size: rem-calc(22);
            line-height: 150%;
        }
    }
}

// text links

a {
    &.primary {
        font-weight: $global-weight-bold;
    }

    &.secondary {
        color: $light-blue;
    }

    &.margin-left {
        margin-left: 1.25rem;

        @include breakpoint(medium) {
            margin-left: 1.875rem;
        }
    }
}

.red {
    color: red;
}