// Hamburger Settings
// ==================================================
$hamburger-padding-x           : 20px !default;
$hamburger-padding-y           : 14px !default;
$hamburger-layer-width         : 21px !default;
$hamburger-layer-height        : 3px !default;
$hamburger-layer-spacing       : 3px !default;
$hamburger-layer-color         : #000 !default;
$hamburger-layer-border-radius : 3px !default;
$hamburger-hover-opacity       : 0.7 !default;
$hamburger-active-layer-color  : $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter   : false !default;
$hamburger-hover-filter       : opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

.top-bar,
.top-bar-mob {
    background: $white;
    max-width: $topbar-max-width;
    padding: 0 1.25rem;
    border-bottom: 1px solid $light-gray;

    ul li {
        list-style: none;

        a, .dropdown {
            color: $body-font-color;
            cursor: pointer;
            display: inline-block;

            &:hover,
            &.active {
                color: $light-blue;
            }
        }

        .button {
            color: $white;
        }
    }
}

.top-bar {

    .text-logo img {
        width: rem-calc(200);
        padding: 0.2rem 1.25rem 0.2rem 0;
        border-right: 1px solid $light-gray;

        @media screen and (max-width: rem-calc(1360)) {
            width: rem-calc(170);
            padding-top: 0.3rem;
        }
    }

    .top-bar-left {
        ul {
            margin-top: 1.75rem;

            @media screen and (max-width: rem-calc(1360)) {
                margin-top: 1.4rem;
            }
        }
    }

    ul {

        &.inline-menu {
            li {
                display: inline-block;
                vertical-align: middle;
                margin-left: 1.875rem;

                @media screen and (max-width: rem-calc(1360)) {
                    margin-left: 1.2rem;
                }
            }

            .dropdown-items {
                display: none;
            }

            .dropdown-items.open {
                display: block;
            }

            &.float-right {
                margin-top: rem-calc(4);

                li {
                    .dropdown:after {
                        content: "";
                        display: inline-block;
                        width: 9px;
                        height: 8px;
                        margin-left: 0.5rem;
                        background-image: url('/themes/wf/assets/svg/icn-chevron-down.svg');
                        background-repeat: no-repeat;
                    }

                    .dropdown:hover:after {
                        background-image: url('/themes/wf/assets/svg/icn-chevron-down-blue.svg');
                    }
                }
            }
        }

        &.dropdown-menu {
            li {
                display:block;
                margin-left: 0;
            }
        }

        .card {
            top: rem-calc(47);
            padding: 0.5rem 0.75rem;
            border-radius: 0 0 $global-radius $global-radius;
            width: rem-calc(120);
        }
    }

    .contact-number {
        margin-top: 0.5rem;
        margin-right: 1.25rem;

        @media screen and (min-width: rem-calc(1361)) {
            margin-right: 2rem;
        }

        @media screen and (max-width: rem-calc(1220)) {
            display: none;
        }

        .icon {
            margin-right: 0.25rem;
        }
    }
}

.top-bar-mob {
    position: relative;

    ul {
        li {
            font-size: 1.125rem;
            margin-bottom: 1.25rem;
        }
    }

    .mob-menu-dropdown {
        display: none;
    }

    .text-logo img {
        width: rem-calc(170);

        @include breakpoint(medium) {
            width: rem-calc(200);
        }
    }

    .hamburger {
        padding: $hamburger-padding-y $hamburger-padding-x;
        display: inline-block;
        cursor: pointer;

        transition-property: opacity, filter;
        transition-duration: 0.15s;
        transition-timing-function: linear;

        // Normalize (<button>)
        font: inherit;
        color: inherit;
        text-transform: none;
        background-color: transparent;
        border: 0;
        margin: 0;
        overflow: visible;
        position: absolute;
        top: 0;
        right: 0;
        outline: none;

        &:hover {
            @if $hamburger-hover-use-filter == true {
            filter: $hamburger-hover-filter;
            }
            @else {
            opacity: $hamburger-hover-opacity;
            }
        }

        &.is-active {
            &:hover {
                @if $hamburger-hover-use-filter == true {
                    filter: $hamburger-active-hover-filter;
                }
                @else {
                    opacity: $hamburger-active-hover-opacity;
                }
            }

            .hamburger-inner,
            .hamburger-inner::before,
            .hamburger-inner::after {
                background-color: $hamburger-active-layer-color;
            }
        }
    }

    .hamburger-box {
        width: $hamburger-layer-width;
        height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
        display: inline-block;
        position: relative;
    }

    .hamburger-inner {
        display: block;
        top: 50%;
        margin-top: $hamburger-layer-height / -2;

        &,
        &::before,
        &::after {
            width: $hamburger-layer-width;
            height: $hamburger-layer-height;
            background-color: $hamburger-layer-color;
            border-radius: $hamburger-layer-border-radius;
            position: absolute;
            transition-property: transform;
            transition-duration: 0.15s;
            transition-timing-function: ease;
        }

        &::before,
        &::after {
            content: "";
            display: block;
        }

        &::before {
            top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
        }

        &::after {
            bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
        }
    }
    .hamburger--collapse {
    .hamburger-inner {
            top: auto;
            bottom: 0;
            transition-duration: 0.13s;
            transition-delay: 0.13s;
            transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

            &::after {
            top: ($hamburger-layer-spacing * 2 + $hamburger-layer-height * 2) * -1;
            transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                        opacity 0.1s linear;
            }

            &::before {
            transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                        transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }
        }

        &.is-active {
            .hamburger-inner {
            transform: translate3d(0, ($hamburger-layer-spacing + $hamburger-layer-height) * -1, 0) rotate(-45deg);
            transition-delay: 0.22s;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

                &::after {
                    top: 0;
                    opacity: 0;
                    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                                opacity 0.1s 0.22s linear;
                }

                &::before {
                    top: 0;
                    transform: rotate(-90deg);
                    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                                transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
                }
            }
        }
    }
}

.covid-19-notice {
    padding: 20px 0;
    background-color: rgba(226, 11, 7, 0.08);
    color: rgb(226, 11, 7);
}

.covid-19-notice a {
    color: rgb(226, 11, 7);
    text-decoration: underline;
}

.covid-19-notice.hide-for-large {
    text-align: center;
}

#covid-19-modal h1 {
    margin-top: 25px;
    margin-bottom: 25px;
}