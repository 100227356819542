.card {
    background: $white;
    box-shadow: 0 4px 20px 0 rgba(45,44,49,0.10);
    border-radius: $global-radius;
    padding: 1.25rem;

    &.no-padding {
        padding: 0;
    }
}

.paid-claims {
    display: flex !important;
    flex-flow: inherit !important;
    width: 100%;

    .card {
        flex: 1;
        margin-bottom: 1rem;
        white-space: normal;

        @include breakpoint(large) {
            margin-bottom: 0;
        }

        .text-black.uppercase {
            margin-bottom: 0.5rem;
        }

        img {
            margin: 0 0.2rem 0.3rem 0;
        }

        p.margin-bottom-0 {
            margin-top: 0.3rem;
        }

        &.grid {
            @include breakpoint(large) {
                margin-bottom: 1rem;
            }
        }
    }
}

.paid-claims-grid {
    .card {
        img {
            margin: 0 0.2rem 0.3rem 0;
        }
    }
}