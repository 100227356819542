.footer-contact,
footer {
    background-color: $royal-blue;
}

.footer-contact {
    max-width: 100%;
    padding: rem-calc(40) 1.25rem 0;

    @include breakpoint(medium) {
        padding: rem-calc(60) 1.25rem 0;
    }

    .grid-x {
        border-bottom: rem-calc(1) solid $white;
    }

    .button {
        display: block;

        @include breakpoint(medium) {
            display: inline-block;
        }
    }
}

footer {
    padding: rem-calc(40) 0;
    
    .list-container {
        .cell:nth-child(4),
        .cell:nth-child(5) {
            @include breakpoint(medium) {
                margin-top: rem-calc(30);
            }

            @include breakpoint(large) {
                margin-top: 0;
            }
        }
    }

    .mini-heading {
        color: $white;
        padding-top: rem-calc(40);

        @include breakpoint(medium) {
            padding-top: rem-calc(60);
        }
    }

    ul {
        margin: 0;

        li {
            list-style: none;
            line-height: 115%;
            margin: 1.5rem 0 0;
            font-size: 0.875rem;
            color: $light-gray;

            @include breakpoint(medium) {
                margin: 1rem 0 0;
            }

            a {
                color: $light-gray;

                &:hover {
                    color: $warning-color;
                }
            }
        }
    }

    img.contact-icon {
        height: rem-calc(16);
    }

    .chunky-heading {
        vertical-align: top;
    }

    .grid-x.hide-for-medium {
        ul li:first-child {
            margin-top: 0;
        }

        .contact.text-center {
            border-top: rem-calc(1) solid $white;
            padding: rem-calc(40) 0 0;
        }
    }
}
