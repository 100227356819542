label {
    font-family: $body-font-family;
    font-size: 0.875rem;
    font-weight: $global-weight-semibold;
    margin-bottom: 0.4rem;
}

#{text-inputs()},
textarea,
select {
    border: 1px solid $light-gray;
    padding: 1rem 0.75rem; 
    margin-bottom: 1.875rem;
    border-radius: rem-calc(4);
    font-size: 1rem;
    font-family: $body-font-family;
    color: $body-font-color;

    &:focus {
        border: 1px solid $light-gray;
    }

    &.is-invalid-input {
        border-color: $alert-color;
    }
}

select {
    padding: 0.5rem 1.5rem 0.5rem .75rem;
}

textarea {
    $height: ($input-font-size * unitless-calc($input-line-height))+(get-side($input-padding, 'top') + get-side($input-padding, 'bottom')) - rem-calc(1);

    min-height: $height;
    resize: none;
    transition: height 500ms;

    &:focus {
        height: $height * 2.5;
        transition: height 500ms;
    }
}

input[type=checkbox] {
    -webkit-appearance: none;
    -webkit-user-select: all !important;
    pointer-events: auto !important;
    background-color: $white;
    border: $input-border-focus !important;
    border-radius: rem-calc(2);
    box-shadow: none;
    display: inline-block;
    line-height: normal;
    margin: 0 rem-calc(10) 0 0;
    padding: rem-calc(8);
    position: relative;
    top: rem-calc(1);
    width: auto;

    &:active,
    &:checked:active {
        box-shadow: none;
    }

    &:checked {
        background-color: $primary-color;
        border: rem-calc(1) solid $primary-color !important;
        box-shadow: none;
        color: $white;

        &::after {
            content: url(../../../wf/assets/svg/icn-tick-white.svg);
            font-size: rem-calc(9);
            position: absolute;
            top: 0.15rem;
            left: 0.17rem;
            color: $white;
        }
    }

    &:hover {
        cursor: pointer;
        border: rem-calc(1) solid $primary-color;
    }
    
    &:focus {
        outline: none;
    }
}

.input-group {
    margin-bottom: rem-calc(24);

    @include breakpoint(medium) {
        margin-bottom: rem-calc(30);
    }

    .input-group-label {
        background: $xlight-gray;
        border: 1px solid $light-gray;
        color: $dark-gray;

        &.prepend {
            border-right: none;
            border-radius: 4px 0 0 4px;
        }

        &.append {
            border-left: none;
            border-radius: 0 4px 4px 0;
        }

        &.prepend,
        &.append {
            &.no-background {
                background: $white;
            }
        }
    }

    input, select {
        margin-bottom: 0;
        border-radius: 0;

        &.white {
            background: $white;
        }
    }

    .button {
        font-size: 1rem;
    }
}

input {
    &:read-only {
        border: none;
        color: #5e5c67;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        pointer-events: none;
    }
}

td input {
    margin-bottom: 0px !important;
}

div.form-error {
    display: none;
}

label.error {
    color: #E10808;
    font-size: 0.8rem;
    position: relative;
    top: rem-calc(-25);
    font-weight: $global-weight-normal;
}

input.error, select.error, textarea.error {
    border: 1px solid #E10808;
}

.alert-error {
    padding: rem-calc(16);
    color: #E10808;
    border: 1px solid #E10808;
    border-radius: $global-radius;
    background: rgba(225,8,8,0.05);
    font-family: $body-font-family;
    font-size: 0.875rem;
    font-weight: $global-weight-normal;
}