.bg-white {
  background: $white;
}

.bg-l-blue {
  background: $light-blue;
}

.bg-navy {
  background: $royal-blue;
}

.bg-xlight {
  background: $xlight-gray;
}

.bg-light {
  background: $light-gray;
}

.bg-dark {
  background: $dark-gray;
}

.bg-black {
  background: $black;
}

.bg-black-transparent {
  background: fade-out($black, 0.05);
}

.bg-warning {
  background: $warning-color;
}

.bg-cover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &.bg-bottom {
    background-position: bottom;
  }

  &.bg-left {
    background-position: left;
  }

  &.bg-right {
    background-position: right;
  }

  &.bg-top {
    background-position: top;
  }
}

.psuedo-cover {
  height: auto;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: auto;
}

.medium-vertical-center {
  @include breakpoint(medium) {
    @include vertical-center;
  }
}

.xmedium-vertical-center {
  @include breakpoint(xmedium) {
    @include vertical-center;
  }
}

.z-1 {
  z-index: 1;
}

.in-front {
  z-index: 10;
}

.behind {
  z-index: -1;
}

.large-bg-none {
  @include breakpoint(large) {
    background: none;
  }
}

.contentimage-position-absolute {
  @include breakpoint($content-image-breakpoint) {
    position: absolute !important;
  }
}

.show-for-retina {
  @media not screen and (-webkit-min-device-pixel-ratio: 2),
  not screen and (min-resolution: 192dpi) {
    display: none !important;
  }
}

.hide-for-retina {
  @include breakpoint(retina) {
    display: none !important;
  }
}

.swipe-icon {
  height: rem-calc(30);
}

.form-alert-message {
  opacity: 0;
  transition: all .35s ease-in-out;
}

.form-alert-message--show {
  opacity: 1;
}
