.product-cards {
    .covered-components-list ul {
        margin-left: 0;
        li {
            list-style: none;
            line-height: 150%;
            margin-bottom: 0.35rem;

            img {
                padding-bottom: rem-calc(5)
            }
        }
    }

    .whats-covered {
        border-top: rem-calc(1) solid $light-gray;
        padding-top: 1.5rem;
    }

    .card {
        margin-bottom: 2rem;
        border: 2px solid $white;

        @include breakpoint(large) {
            margin-bottom: 0;
        }

        &.most-popular {
            border: 2px solid $light-blue;
        }
    }

    .product-logo {
        margin: 0.625rem auto 1.25rem;
    }

    .button.primary {
        margin-top: 1.625rem;
    }

    .modal-link {
        padding-top: 1.5rem;
    }
}

.most-popular {
    border: 2px solid $light-blue;
    margin-top: 2rem;
    overflow: visible;

    @include breakpoint(large) {
        margin-top: 0;
    }

    .most-popular-tag {
        top: -1.7rem;
        font-weight: $global-weight-semibold;
        font-size: 0.875rem;
        background: $light-blue;
        color: $white;
        padding: 0.1rem 0.625rem 0.2rem;
        border-radius: 3px 3px 0 0;
    }
}

.our-plans .product-cards {
    .button {
        margin-bottom: 1.8rem;
    }
}

.product-tile-snapshot {
    h5 {
        margin-bottom: 1.8rem;
    }

    span.uppercase,
    span.price,
    span.price.annual {
        color: $black;
    }
    
    span.uppercase {
        font-weight: $global-weight-semibold;
    }

    span.price,
    span.price.annual {
        font-weight: $global-weight-bold;
        font-size: 1.875rem;
    }
}

.product-card-mini {
    span.price {
        font-weight: $global-weight-bold;
        font-size: 1.875rem;
        color: $black;
    }

    .position-absolute {
        bottom: 0;
        right: 0;
    }

    .button {
        margin-bottom: rem-calc(12);
    }

    .price {
        line-height: 100%;
    }
}

.plan-icons {
    img.right,
    img.left {
        width: rem-calc(140);
        top: -7rem;

        @include breakpoint(medium) {
            width: rem-calc(160);
            top: -8rem;
        }

        @include breakpoint(large) {
            width: rem-calc(180);
            top: -9.6rem;
        }
    }

    img.left {
        left: 1rem;
    }

    img.right {
        right: 1rem;
    }
}

// Toggle
.products {
    .monthly-summary {
        display: block;
        &.hide {
            display: none;
        }
    }
    .monthly {        
        display: inline-block;
        &.hide {
            display: none;
        }
    }
    .annual {
        display: none;
        &.show {
            display: inline-block;
        }
    }
}

.product-tile-snapshot p.plan-description {
    @include breakpoint(large) {
        min-height: rem-calc(72);
    }
}

.product-tile-snapshot p.no-price {
    font-style: italic;
    margin-bottom: 30px;
}
