.scrolling-image {
    overflow-x: auto;
    position: relative;
    white-space: nowrap;

    @include breakpoint(medium) {
        @include xy-grid;
        overflow: visible;
        display: block;
    }
    
    img {
        max-width: none;
        width: 700px;
        margin-left: -30px;

        @include breakpoint(medium) {
            max-width: 100%;
            width: auto;
            margin-left: auto;
        }
    }
}

.horizontal-scroll-wrapper {
    width: 100%;
    position: relative;

    .small-horizontal-scroll {
        -webkit-overflow-scrolling: touch;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
    
        @media screen and (min-width: rem-calc(1070)) {
            @include xy-grid;
            overflow: visible;
            display: block;
            width: 100%;
        }

        .scrolling-child {
            display: inline-block;
            margin-right: 2rem;

            &:last-child {
                margin-right: 0;
            }

            &.icon-scroll-child {
                max-width: rem-calc(200);

                @media screen and (min-width: rem-calc(1200)) {
                    max-width: rem-calc(260);
                }

                h6, p {
                    display: block;
                    white-space:normal;
                }
            }
        }
    
        .card {
            display: inline-block;
            min-width: rem-calc(240);
            margin-right: 1rem;
    
            @media screen and (min-width: rem-calc(1070)) {
                width: 23.25%;
                margin-right: 1%;
                margin-left: 1%;
    
                &:first-child {
                    margin-left: 0;
                }
    
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    
        &::-webkit-scrollbar {
            display: none;
        }
    }
    
    .horizontal-scroll-dots {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
    
        .dot {
            display: inline-block;
            width: 0.7rem;
            height: 0.7rem;
            background-color: $dark-gray;
            border-radius: 50%;
            margin: 0 0.5rem;
    
            &.active {
                background-color: #1673FF;
            }
        }

        @media screen and (min-width: rem-calc(1070)) {
            display: none;
        }
    }
}

img.swipe-icon {
    height: 2.5rem;
}